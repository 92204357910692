import { createStore as reduxCreateStore } from "redux"

const arrowRightShape = `polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)`;
const arrowLeftShape = `polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)`;
const circleShape = `circle(50% at 50% 50%)`;

const reducer = (state, action) => {
    switch(action.type){
        case(`TOGGLE_MOBILE_NAV`):
            return Object.assign({}, state, {
                mobileNavIsOpen: !state.mobileNavIsOpen,
            })
        case(`CLOSE_MOBILE_NAV`):
            return Object.assign({}, state, {
                mobileNavIsOpen: false,
            })
        case(`SET_BG_COLOR`):
            switch(action.payload){
                case('PEACH'):
                    return {...state, bgColor: 'PEACH'}
                case('ORANGE'):
                    return {...state, bgColor: 'ORANGE'}
                case('PINK'):
                    return {...state, bgColor: 'PINK'}
                default:
                    return {...state, bgColor: 'PEACH'}
            }
        case(`SCALE_CURSOR_UP`):
            return {...state,cursorScale: 2};

        case(`SCALE_CURSOR_DOWN`):
            return {...state,cursorScale: 1};
        case(`CURSOR_SHAPE`):
            switch(action.payload){
                case('ARROW_RIGHT'):
                    return {...state, cursorShape: arrowRightShape}
                case('ARROW_LEFT'):
                    return {...state, cursorShape: arrowLeftShape}
                default:
                    return {...state, cursorShape: circleShape}
            }
    }
    return state
}

const initialState = { 
    mobileNavIsOpen: false,
    bgColor: 'PEACH',
    cursorScale: 1,
    cursorShape: circleShape,
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore