import './static/fonts.css'
import './static/global.css'
import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Provider } from 'react-redux'
import createStore from './src/state/createStore'

export const wrapRootElement = ({ element }) => {
  const store = createStore()
  return <Provider store={store}>{element}</Provider>
}

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.3 * 1000 * 2
  console.log('ok after', TRANSITION_DELAY, location)

  // if it's a "normal" route
  // if (location.action === "PUSH") {
  window.setTimeout(() => {
    window.scrollTo(0, 0)
    console.log('ok again ', TRANSITION_DELAY)
  }, TRANSITION_DELAY)
  // }
  // if we used the browser's forwards or back button
  // else {
  //     const savedPosition = getSavedScrollPosition(location) || [0, 0]
  //     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  // }
  return false
}

// export const onClientEntry = ()=>{
//     modifyCursorState(document);
// }

// const modifyCursorState = (document)=>{
//     const body = document.body;
//     const arrowRight = `polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)`;
//     const arrowLeft = `polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)`;
//     const circle = `circle(50% at 50% 50%)`;
//     const setScale  = (scale)=>{ body.style.setProperty('--cursor-scale',scale); }
//     const setClip   = (clip)=>{ body.style.setProperty('--cursor-clip',clip); }
//     // const setCss   = (css)=>{ body.style.setProperty('--cursor-c',css); }

//     // document.addEventListener("mousemove", function(e) {
//     //     const newCss = `--cursor-x: ${e.clientX}px; --cursor-y: ${e.clientY}px`;
//     //     setCss(newCss);
//     // });
//     document.querySelectorAll("a,button").forEach( el => {
//         el.addEventListener("mouseenter", ()=>setScale(2));
//         el.addEventListener("mouseleave", ()=>setScale(1));
//     })
//     document.querySelectorAll(".swiper-wrapper a,.swiper-nav-next").forEach( el => {
//         el.addEventListener("mouseenter", ()=>setClip(arrowRight));
//         el.addEventListener("mouseleave", ()=>setClip(circle));
//     })
//     document.querySelectorAll(".swiper-nav-prev").forEach( el => {
//         el.addEventListener("mouseenter", ()=>setClip(arrowLeft));
//         el.addEventListener("mouseleave", ()=>setClip(circle));
//     })
// }

